




























































import Vue from 'vue';
import { mapMutations } from 'vuex';
import axios from 'axios';
import 'vue-loading-overlay/dist/vue-loading.css';

const Ripple = require('vue-ripple-directive');

export default Vue.extend({
  directives: {
    Ripple,
  },
  data() {
    const form: {
      uploadFile: Blob | undefined;
    } = {
      uploadFile: undefined,
    };
    return {
      cameraId: 'Test',
      imageId: '',
      question: '',
      answer: '',
      topic: '工廠逸散',
      topics: [
        { val: '車辨及車斗覆蓋' },
        { val: '露天燃燒' },
        { val: '河川揚塵' },
        { val: '煙囪排放' },
        { val: '工地揚塵' },
        { val: '工廠逸散' },
      ],
      form,
    };
  },
  computed: {
    imageUrl() {
      if (!this.imageId) return '';

      const baseUrl =
        process.env.NODE_ENV === 'development' ? 'http://localhost:9000/' : '/';

      return `${baseUrl}Image/${this.imageId}`;
    },
  },
  beforeDestroy() {},
  methods: {
    ...mapMutations(['setLoading']),
    delay(milliseconds: number): Promise<void> {
      return new Promise(resolve => setTimeout(resolve, milliseconds));
    },
    async uploadImage() {
      let formData = new FormData();
      formData.append('image', this.form.uploadFile as Blob);
      try {
        let res = await axios.post(`/Image/${this.cameraId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (res.status === 200) {
          // await this.delay(2000)
          this.imageId = res.data;
        } else {
          this.$bvModal.msgBoxOk(`上傳失敗 ${res.status} - ${res.statusText}`, {
            headerBgVariant: 'danger',
          });
        }
      } catch (err) {
        this.$bvModal.msgBoxOk(`上傳失敗 ${err}`, {
          headerBgVariant: 'danger',
        });
      }
    },
    async ask() {
      this.setLoading({ loading: true, message: 'AI 推理中' });
      try {
        let res = await axios.post(`/AskQuestion`, {
          imageId: this.imageId,
          model: 'Test',
          question: this.question,
          topic: this.topic,
        });

        if (res.status === 200) {
          console.info(res.data);
          this.answer = res.data.response;
        } else {
          this.$bvModal.msgBoxOk(`詢問失敗 ${res.status} - ${res.statusText}`, {
            headerBgVariant: 'danger',
          });
        }
      } catch (err) {
        this.$bvModal.msgBoxOk(`詢問失敗 ${err}`, {
          headerBgVariant: 'danger',
        });
      } finally {
        this.setLoading({ loading: false });
      }
    },
  },
});
